<template>
  <v-container
    fluid
    fill-height
    class="d-block"
  >
    <v-row
      no-gutters
    >
      <v-col
        cols="6"
      >
        <v-text-field
          v-model="fieldKey"
          label="Field Key"
          outlined
          hint="company_name for example. Please use underscores!"
        />
        <v-select
          v-model="fieldType"
          :items="fieldTypes"
          outlined
          label="Field Type"
        />
        <h2>Field Label</h2>
        <v-card>
          <v-tabs
            v-model="tab"
            background-color="primary"
            dark
            grow
          >
            <v-tab
              v-for="item in tabItems"
              :key="item.tab"
            >
              <v-badge
                avatar
                offset-x="2px"
                offset-y="6px"
                color="transparent"
                :value="item.content && item.content.length"
              >
                <template v-slot:badge>
                  <v-icon color="green">
                    {{ checkIcon }}
                  </v-icon>
                </template>
                {{ item.tab }}
              </v-badge>
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="item in tabItems"
              :key="item.tab"
            >
              <v-card flat>
                <v-card-text>
                  <v-text-field
                    v-model="item.content"
                    outlined
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
      <v-col
        cols="6"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { mdiCheck } from '@mdi/js'
import fieldTypes from '@/enums/fieldTypes'

export default {
  data () {
    return {
      fieldKey: null,
      fieldType: null,
      fieldTypes,
      tab: null,
      checkIcon: mdiCheck,
      tabItems: null
    }
  },
  computed: {
    ...mapState({
      account: state => state.account.account,
      settings: state => state.settings.settings
    })
  },
  watch: {
    settings: {
      handler (value) {
        if (!value) return
        this.$set(this, 'tabItems', value.activeLanguages.map(lang => ({
          tab: lang,
          content: null
        })))
      },
      immediate: true
    }
  }
}
</script>
